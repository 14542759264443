<template>
    <ValidationObserver ref="validator" slim>
        <ValidationProvider v-slot="{ errors }" rules="required|max:4">
            <div class="confirmation">
                <h3>Авторизация</h3><br>
                на номер {{ auth.phone }} отправлено СМС с кодом подтверждения<br><br>
                <v-text-field
                    v-model="code"
                    label="Введите код из смс"
                    counter="4"
                    outlined
                    :error-messages="errors[0]"
                    @keyup.enter="confirm"
                />
                <div v-if="error" class="confirmation__error">{{ error }}</div>
                <v-btn 
                    v-if="accessDenied"
                    class="mt-4"
                    @click="back">Назад</v-btn>
                <v-btn 
                    v-else
                    color="primary"
                    :disabled="loading"
                    @click="confirm">
                    Подтвердить
                    <template v-if="loading">
                        &nbsp;
                        <v-progress-circular
                            :size="20"
                            color="white"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                </v-btn>
            </div>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';

    export default {
        name: 'Confirmation',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            code: '',
            accessDenied: false,
            error: null,
            loading: false
        }),
        computed: {
            ...mapState('auth', ['user', 'auth']),
        },
        methods: {
            async confirm() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    this.loading = true;
                    const data = await store.dispatch('auth/confirm', { code: this.code });
                    if(data.error) {
                        this.error = data.error;
                    } else {
                        await store.dispatch('auth/fetch');
                        if(this.user.id) {
                            this.$router.push({ name: 'home' });
                        } else {
                            this.accessDenied = true;
                            this.error = 'У вас не достаточно прав доступа. Пожалуйста обратитесь к вашему менеджеру.';
                        }
                    }
                    this.loading = false;
                }
            },
            async back() {
                await store.dispatch('auth/logout');
                this.$router.push({ name: 'user-authorization' });
            }
        }
    }
</script>


<style lang="scss">
.confirmation {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 30%;
    left: 50%;
    margin: 0 0 0 -200px;
    padding: 0 20px;

    &__error {
        font-size: 14px;
        color: #ff5252 !important;
        caret-color: #ff5252 !important;
        padding: 0 10px 10px;
        text-align: center;
    }
}
</style>
